import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactPlayer from 'react-player';
import Image from "../../../assets/Mask Group 9.png";
import PlayIcon from "../../../assets/Subtraction 1.svg";
import integral_calculas from "../../../assets/Experience_Our_magic/Thumbnails/Integral calculus.png"
import linear_algebra from "../../../assets/Experience_Our_magic/Thumbnails/Linear Algebra.png"
import matrices from "../../../assets/Experience_Our_magic/Thumbnails/Matrices.png"

export const VCmain = () => {
  const sliderRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    centerMode: true,
    centerPadding: '0',
    slidesToShow: 1,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    dots: true,
    dotsClass: 'slick-dots flex justify-center items-center mt-4', // Tailwind classes
    focusOnSelect: true,
    beforeChange: (prev: any, next: any) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 768, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handlePlay = (url: any, index: any) => {
    setIsPlaying(true);
    setVideoUrl(url);
    setCurrentSlide(index);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handlePrev = () => {
    (sliderRef.current as unknown as Slider)?.slickPrev();
  };

  const handleNext = () => {
    (sliderRef.current as unknown as Slider)?.slickNext();
  };


  function getYouTubeThumbnail(videoId: any) {
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  }

  const videos = [
    {id:'9S6Bs77Sa-E',tumbnail:`${integral_calculas}` , url:'https://youtu.be/9S6Bs77Sa-E'},
    {id:'Ev2yNhTGUY4',tumbnail:`${linear_algebra}`, url:'https://youtu.be/Ev2yNhTGUY4'},
    {id:'bPpk0wAfj-E',tumbnail:`${matrices}`, url:'https://youtu.be/bPpk0wAfj-E'},
  ];

  return (
    <div className='absolute max-w-[1600px] left-1/2 transform -translate-x-1/2 xs:-translate-y-1/2 md:-translate-y-1/2 xl:-translate-y-1/3 mt-[-5rem] md:mt-[-4rem] xlg:mt-[-2rem] lxl:mt-[-1.5rem]  xs:w-full md:w-[95%] xl:w-[92%] max-w-screen-xl  mx-auto'>
      <div className="absolute w-[60%] opacity-50 hover:opacity-[.5] hover:cursor-pointer mt-7 ml-20 z-0 " onClick={handlePrev}>
        <img src={matrices} alt="Image 1" className="w-[100rem] xs:h-[40vw] md:h-[40vw] slg:h-[35vw] smlg:h-[30vw] xxl:h-[25vw] lxxl:h-[25vw] rounded-[25px] lg:h-[38vw] relative" />
      </div>
      <div className="h-1000 w-[90%] overflow-x-clip lg:w-[70%] mx-auto ">
        <Slider {...settings} className="relative  z-10" ref={sliderRef}>
          {[0, 1, 2].map((index) => (
            <div key={index} className={`w-[60%] relative ${currentSlide === index ? 'z-10' : ''}`}>
              <img src={videos[index].tumbnail} alt="Image 1" className="w-[100rem] xs:h-[50vw] sm:h-[45vw] md:h-[50vw] lg:h-[38vw] slg:h-[40vw] smlg:h-[35vw]  xxl:h-[30vw] lxxl:h-[30vw] xs:rounded-[12px] md:rounded-[25px] relative" />
              {isPlaying && currentSlide === index && (
                <ReactPlayer
                  url={videoUrl}
                  playing={isPlaying}
                  controls
                  width="100%"
                  height="100%"
                  className="absolute inset-0"
                  onPause={handlePause}
                />
              )}
              <div className={`absolute inset-0 flex items-center justify-center ${isPlaying && currentSlide === index ? 'hidden' : ''}`}>
                <img
                  src={PlayIcon}
                  alt="Play Icon"
                  className="xl:w-[90px] lxl:w-[72px] xlg:w-[66px] smlg:w-[60px] msm:w-[54px] md:w-[48px] xs:w-[42px] hover:cursor-pointer"
                  onClick={() => handlePlay(videos[index].url, index)}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="absolute w-[60%] top-0 right-0 opacity-50 hover:opacity-[.5] hover:cursor-pointer mt-7 mr-20 z-0" onClick={handleNext}>
      <img src={matrices} alt="Image 1" className="w-[100rem] xs:h-[40vw] md:h-[40vw] slg:h-[35vw] smlg:h-[30vw] xxl:h-[25vw] lxxl:h-[25vw] rounded-[25px] lg:h-[38vw] relative" />
      </div>
    </div>
  );
};